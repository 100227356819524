import React from 'react';

const samplePrompts = [
  {
    title: 'Customer Support Triage',
    description: 'Extract key info and find the sentiment of tickets',
    href: '/training/prompts?template=customer-support',
    image: '/mail.svg',
  },
  {
    title: 'Extract Prescription Info From Medical Notes',
    description: 'Extract key information from unstructured content',
    href: '/training/prompts?template=medicine-extraction',
    image: '/social.svg',
  },
  {
    title: 'Tweet Sentiment Analysis',
    description: 'Analyse the sentiment of tweets about your product',
    href: '/training/prompts?template=tweet-sentiment',
    image: '/writing.svg',
  },
  {
    title: 'Template Variable Analysis',
    description: 'See how we generated our own prompt template analyser',
    href: '/training/prompts?template=json-schema',
    image: '/code.svg',
  },
];

interface PromptCardProps {
  title: string;
  description: string;
  href: string;
  image: string;
}

const PromptCard: React.FC<PromptCardProps> = ({
  title,
  description,
  href,
  image,
}) => (
  <a
    href={href}
    className="text-xs font-semibold text-indigo-700 hover:text-indigo-800"
  >
    <div
      className="border-gray-150 hover:scale-101 relative flex h-full flex-col overflow-hidden rounded-md border bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:cursor-pointer hover:bg-gray-50 hover:shadow-md"
      style={{minHeight: '150px'}}
    >
      <div className="relative z-10 flex-grow">
        <h3 className="mb-1 text-sm font-semibold text-gray-900">{title}</h3>
        <p className="max-w-[200px] text-xs text-gray-500">{description}</p>
        <div className="relative z-10 mt-auto pt-4">Optimize this prompt</div>
      </div>
      <img
        src={image}
        alt={title}
        className="absolute bottom-2 right-2 z-0 h-24 w-24 opacity-75"
      />
    </div>
  </a>
);

const MigratePromptSection: React.FC = () => (
  <div className="relative mx-6 my-2 bg-[#F8F8F8] text-left">
    <div className="p-6">
      <h2 className="font-chivo mb-4 text-base font-light text-gray-900">
        Start saving up to 95% on your LLM tasks, see how it works:
      </h2>
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
        {samplePrompts.map((prompt, index) => (
          <PromptCard key={index} {...prompt} />
        ))}
      </div>
    </div>
  </div>
);

export default MigratePromptSection;
