import {TrashIcon} from '@heroicons/react/20/solid';
import {Textarea} from '@nextui-org/react';
import React from 'react';

export default function TestInput({
  task,
  setTask,
  updateTask,
  currentJSONSchema,
  isExternal,
}) {
  function calculateRows(text) {
    if (!text || !text.split) {
      return 3;
    }
    const lines = text.split('\n').length;
    const minRows = isExternal ? 12 : 3;
    return Math.max(lines, minRows);
  }

  const handleDelete = index => {
    // Since there is only one task, clear the prompt and target:
    if (task.examples.length === 1) {
      setTask({
        ...task,
        examples: [
          {
            input: '',
            target: '',
          },
        ],
      });
      return;
    }
    // Remove the example at the given index:
    const newExamples = task.examples.filter((_, idx) => idx !== index);
    setTask({...task, examples: newExamples});
  };

  if (
    currentJSONSchema &&
    currentJSONSchema.properties &&
    Object.keys(currentJSONSchema.properties).length
  ) {
    const handleInputChange = (index, propertyName, value) => {
      const newExamples = task.examples.map((example, i) => {
        if (index === i) {
          return {
            ...example,
            input: {
              ...example.input,
              [propertyName]: value,
            },
          };
        }
        return example;
      });

      setTask({
        ...task,
        examples: newExamples,
      });
    };

    const handleOutputChange = (index, value) => {
      const newExamples = task.examples.map((example, i) => {
        if (index === i) {
          return {
            ...example,
            target: value,
          };
        }
        return example;
      });

      setTask({
        ...task,
        examples: newExamples,
      });
    };

    return (
      <div className="mb-6 flex flex-col gap-6">
        {task.examples.map((example, index) => (
          <div
            key={`example-${index}`}
            className="group relative border border-gray-200 bg-white p-2 text-left shadow-md"
          >
            {Object.entries(
              currentJSONSchema && currentJSONSchema.properties,
            ).map(([propertyName, propertyDetails], propIndex) => (
              <div
                className="mb-2 flex flex-col"
                key={`property-${propertyName}-${index}-${propIndex}`}
              >
                <Textarea
                  label={
                    propertyName.charAt(0).toUpperCase() + propertyName.slice(1)
                  }
                  radius="none"
                  type="text"
                  id={`input-${propertyName}-${index}`}
                  placeholder={`Enter a ${propertyName}...`}
                  value={example.input[propertyName] || ''}
                  onChange={e =>
                    handleInputChange(index, propertyName, e.target.value)
                  }
                />
              </div>
            ))}
            <div className="mb-2 flex flex-col">
              <Textarea
                label="Expected Output"
                radius="none"
                type="text"
                id={`output-${index}`}
                placeholder="Enter your expected output"
                value={example.target || ''}
                onChange={e => handleOutputChange(index, e.target.value)}
              />
            </div>
            <button
              onClick={() => handleDelete(index)}
              className="text-black-600 absolute right-2 top-2 rounded-full p-2 opacity-0 transition-opacity hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 group-hover:opacity-100"
              type="button"
              aria-label="Delete example"
            >
              <TrashIcon
                className="text-black-400 h-4 w-4"
                aria-hidden="true"
              />
            </button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mb-6">
      {task.examples.map((example, index) => {
        const inputRows = calculateRows(example.input);
        const targetRows = calculateRows(example.target);
        const rowHeight = Math.min(10, Math.max(3, inputRows, targetRows));
        return (
          <div key={index} className="group mb-4 flex items-center gap-4">
            <div className="flex-grow">
              <Textarea
                id={`input-${index}`}
                placeholder="Input value"
                radius="none"
                required
                disableAutosize
                style={{height: `${rowHeight * 20}px`}}
                value={example.input}
                onChange={e => {
                  const newTask = updateTask(task, index, {
                    ...example,
                    input: e.target.value,
                  });
                  setTask(newTask);
                }}
              />
            </div>
            <div className="flex-grow">
              <Textarea
                id={`output-${index}`}
                placeholder="Expected output"
                radius="none"
                required
                disableAutosize
                style={{height: `${rowHeight * 20}px`}}
                value={example.target}
                onChange={(e: any) => {
                  const newTask = updateTask(task, index, {
                    ...example,
                    target: e.target.value,
                  });
                  setTask(newTask);
                }}
              />
            </div>
            <div className="flex-none">
              <button
                onClick={() => handleDelete(index)}
                className="text-black-600 rounded-full p-2 opacity-0 transition-opacity hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 group-hover:opacity-100"
                type="button"
                aria-label="Delete example"
              >
                <TrashIcon
                  className="text-black-400 h-4 w-4"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
