import {
  ChevronRightIcon,
  ChevronLeftIcon,
  XMarkIcon,
  CodeBracketIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  BookmarkIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tab,
  Tabs,
} from '@nextui-org/react';
import React, {useEffect, useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {ScoringTooltip} from './ScoringTooltip';
import {Completion} from '../types/Completion';
import {PromptNode} from '../types/PromptNode';
import {TaskInfo} from '../types/TaskInfo';
import {scoreToColor} from '../utils/scoreToColor';
import {
  prettyLatency,
  to100pointScale,
  to2SignificantFigures,
} from '../utils/string';

export interface PromptCandidatesDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  candidates: PromptNode[];
  selected: string | null;
  model: string;
  taskInfo?: TaskInfo;
  examples: Array<{input: object; target: string}>;
  bestNodeId?: string | null;
  originalNodeId?: string;
  allPrompts?: PromptNode[];
  trainThisPrompt: (prompt: PromptNode) => void;
  trainingRun: {model: string};
}

export const PromptCandidatesDetailsModal: React.FC<
  PromptCandidatesDetailsModalProps
> = ({
  isOpen,
  onClose,
  candidates,
  selected,
  model,
  taskInfo,
  examples,
  originalNodeId,
  bestNodeId,
  allPrompts,
  trainThisPrompt,
  trainingRun,
}) => {
  const [displayPromptIndex, setDisplayPromptIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState('overview');
  const [selectedCompletion, setSelectedCompletion] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setDisplayPromptIndex(
      candidates.findIndex(candidate => candidate.id === selected),
    );
  }, [isOpen]);

  const displayPrompt = useMemo(() => {
    return candidates[displayPromptIndex];
  }, [displayPromptIndex]);

  const handleNextButton = () => {
    if (displayPromptIndex < candidates.length - 1) {
      setDisplayPromptIndex(displayPromptIndex + 1);
    } else {
      setDisplayPromptIndex(0);
    }
  };

  const handlePreviousButton = () => {
    if (displayPromptIndex > 0) {
      setDisplayPromptIndex(displayPromptIndex - 1);
    } else {
      setDisplayPromptIndex(candidates.length - 1);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const handleKeyRight = (e: KeyboardEvent) => {
        if (e.key === 'ArrowRight') {
          setDisplayPromptIndex(prevIndex =>
            prevIndex < candidates.length - 1 ? prevIndex + 1 : 0,
          );
        }
      };

      const handleKeyLeft = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
          setDisplayPromptIndex(prevIndex =>
            prevIndex > 0 ? prevIndex - 1 : candidates.length - 1,
          );
        }
      };

      window.addEventListener('keydown', handleKeyRight);
      window.addEventListener('keydown', handleKeyLeft);

      return () => {
        window.removeEventListener('keydown', handleKeyRight);
        window.removeEventListener('keydown', handleKeyLeft);
      };
    }
  }, [isOpen]);

  const nodeOverallCost = (completions: Completion[]) => {
    return (
      '$' +
      to2SignificantFigures(
        (completions.reduce(
          (acc: number, cost: {tokenCost: {totalCost: number}}) => {
            return acc + cost.tokenCost.totalCost;
          },
          0,
        ) /
          completions.length) *
          1000,
      )
    );
  };

  if (!displayPrompt) {
    return null;
  }

  return (
    <Modal
      size="5xl"
      className="h-[66vh] max-w-[60%] [@media(max-height:890px)]:h-[75vh]"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      isDismissable={false}
    >
      <ModalContent className="overflow-hidden rounded-none border border-gray-200 pb-3">
        <ModalHeader className="flex flex-row flex-nowrap items-center justify-between bg-[#F9FAFB]">
          <div />
          <div className="flex w-1/2 flex-row flex-nowrap items-center justify-between">
            <Button
              radius="full"
              className="bg-white"
              isIconOnly
              onClick={() => handlePreviousButton()}
            >
              <ChevronLeftIcon className="w-5" />
            </Button>
            <div className="flex flex-grow flex-row flex-nowrap items-center justify-center">
              <h3 className="font-medium">
                {originalNodeId === displayPrompt.id
                  ? 'Original Prompt'
                  : `Prompt Candidate ${
                      allPrompts?.findIndex(
                        prompt => prompt.id === displayPrompt.id,
                      ) || 0
                    }`}
              </h3>
              {displayPrompt.id === bestNodeId && (
                <div className="ml-6 mr-1 flex flex-row bg-[#E1F7E6] px-3 py-1">
                  <img
                    src="/trophy.svg"
                    alt="trophy"
                    className="h-[11] w-[11] pr-1.5 text-[#28871B]"
                  />
                  <span className="font-chivo text-xs font-normal text-[#28871B]">
                    Best
                  </span>
                </div>
              )}
            </div>
            <Button
              radius="full"
              className="bg-white"
              isIconOnly
              onClick={() => handleNextButton()}
            >
              <ChevronRightIcon className="w-5" />
            </Button>
          </div>
          <Button
            radius="full"
            className="bg-[#F9FAFB]"
            isIconOnly
            onClick={() => onClose()}
          >
            <XMarkIcon className="w-5" />
          </Button>
        </ModalHeader>
        <ModalBody className="flex max-h-full w-full max-w-full flex-col flex-nowrap overflow-hidden">
          <div className="flex w-full flex-row flex-nowrap items-center justify-center p-3">
            <div className="flex-grow" />
            <Tabs
              color="primary"
              className="flex-grow justify-center"
              size="lg"
              selectedKey={selectedTab}
              classNames={{
                tabList: 'bg-[#F7F7F7]',
                tab: 'py-5 px-6 font-roboto font-medium text-[#444444] text-sm',
                tabContent:
                  'font-roboto font-medium group-data-[selected=false]:bg-[#F7F7F7] text-[#444444] text-sm',
                cursor: '',
              }}
              radius="full"
              onSelectionChange={key => setSelectedTab(key as string)}
            >
              <Tab title="Overview" key="overview" value="overview" />
              <Tab title="Completions" key="completions" value="completions" />
            </Tabs>
            <div className="flex flex-row flex-nowrap items-center justify-between justify-self-end">
              <Button
                radius="none"
                className="font-roboto bg-[#F9FAFB] px-2 py-1 text-sm text-[#52525B]"
                startContent={<BookmarkIcon className="h-5 w-5 p-0" />}
                onClick={() => {
                  const promptData = JSON.stringify({
                    promptNode: displayPrompt,
                    selectedTrainingModel: trainingRun.model,
                  });
                  localStorage.setItem('temp_prompt_storage', promptData);

                  navigate('/prompts/create');
                }}
              >
                Save Prompt
              </Button>
              <Button
                radius="none"
                className="font-roboto ml-1 bg-[#F9FAFB] px-2 py-1 text-sm text-[#52525B]"
                startContent={<ArrowPathIcon className="h-5 w-5 p-0" />}
                onClick={() => {
                  onClose();
                  trainThisPrompt(displayPrompt);
                }}
              >
                Train on new model
              </Button>
            </div>
          </div>
          <div
            className={`h-full min-h-0 max-w-full grow flex-col flex-nowrap items-start justify-stretch ${selectedTab === 'overview' ? 'flex' : 'hidden'}`}
          >
            <div className="flex h-full min-h-0 w-full grow flex-row items-start justify-start">
              <div className="flex h-full max-h-full flex-row flex-nowrap items-start justify-start">
                <div className="flex min-w-[13rem] flex-col items-start justify-between bg-[#485ED50F] px-5 py-2">
                  <div className="my-3 flex flex-col items-start justify-between">
                    <span className="text-sm text-[#6B7280]">Model</span>
                    <span className="text-sm font-medium text-[#111827]">
                      {model}
                    </span>
                  </div>
                  <div className="my-3 flex flex-col items-start justify-between">
                    <span className="text-sm text-[#6B7280]">Latency</span>
                    <span className="text-sm font-medium text-[#111827]">
                      {prettyLatency(displayPrompt?.latency | 0)}
                    </span>
                  </div>
                  <div className="my-3 flex flex-col items-start justify-between">
                    <span className="text-sm text-[#6B7280]">
                      Cost (per 1000 tasks)
                    </span>
                    <span className="text-sm font-medium text-[#111827]">
                      {displayPrompt?.completions?.length &&
                      displayPrompt?.completions.length > 0
                        ? nodeOverallCost(displayPrompt?.completions)
                        : ''}
                    </span>
                  </div>
                  <div className="my-3 flex flex-col items-start justify-between">
                    <span className="text-sm text-[#6B7280]">
                      Overall Score
                    </span>
                    <span
                      className="text-sm font-medium"
                      style={{
                        color: scoreToColor(displayPrompt?.reward),
                      }}
                    >
                      {to100pointScale(displayPrompt?.reward)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex h-full min-h-0 w-full max-w-full flex-grow flex-row flex-nowrap items-stretch justify-stretch p-0">
                <div className="flex h-full min-h-0 max-w-full flex-grow flex-col items-start justify-stretch px-3">
                  <h3 className="font-roboto mb-2 text-sm font-medium">
                    Prompt
                  </h3>
                  <div className="h-full w-full max-w-full flex-grow overflow-auto bg-[#F9FAFB] p-5">
                    <pre className="font-roboto min-h-0 text-wrap text-sm text-[#6B7280]">
                      {displayPrompt?.prompt}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 flex w-full flex-row flex-nowrap items-center justify-end">
              <Button
                className="font-roboto bg-white text-sm text-[#52525B]"
                startContent={<DocumentDuplicateIcon className="h-5 w-5 p-0" />}
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    JSON.stringify(displayPrompt?.prompt, null, 2)
                      .replace(/\\n/g, '\n')
                      .slice(1, -1),
                  );
                }}
              >
                Copy as Text
              </Button>
              <Button
                className="font-roboto ml-1 bg-white text-sm text-[#52525B]"
                startContent={<CodeBracketIcon className="h-5 w-5 p-0" />}
                onClick={async () => {
                  const prompt = {
                    messages: [
                      {
                        role: 'system',
                        content: displayPrompt.prompt,
                      },
                    ],
                    model: trainingRun.model,
                  };
                  await navigator.clipboard.writeText(JSON.stringify(prompt));
                }}
              >
                Copy as JSON
              </Button>
            </div>
          </div>
          <div
            className={`relative h-4/5 w-full flex-row flex-nowrap items-stretch justify-center ${selectedTab === 'completions' ? 'flex' : 'hidden'}`}
          >
            <div className="flex w-2/5 flex-col items-start justify-start overflow-y-auto">
              {displayPrompt &&
                displayPrompt.completions &&
                displayPrompt.completions.length > 0 &&
                displayPrompt?.completions?.map((completion, index) => (
                  <div
                    key={index}
                    onClick={() => setSelectedCompletion(index)}
                    className={`my-0.5 flex w-full cursor-pointer flex-col items-start justify-start px-5 py-3 ${selectedCompletion === index ? 'z-10 mr-0 bg-[#ABB6ED29]' : 'mr-3 border-r-3 border-white bg-[#F9FAFB]'}`}
                  >
                    <h3 className="font-roboto mb-0 text-sm font-medium">
                      Completion {index + 1}
                    </h3>
                    <div className="m-0 flex w-full flex-row flex-nowrap items-center justify-between text-xs">
                      <p className="font-roboto line-clamp-2 max-h-[5rem] w-4/5 grow-0 overflow-hidden text-ellipsis p-0 text-[#52525B]">
                        {completion.prediction}
                      </p>
                      <span
                        style={{
                          color: scoreToColor(completion.score),
                          marginRight:
                            selectedCompletion === index ? '0' : '-2px',
                        }}
                        className="self-start justify-self-end text-sm font-medium"
                      >
                        {to100pointScale(completion.score)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div className="relative z-0 m-0.5 ml-0 flex max-h-[500px] w-3/5 flex-grow flex-col overflow-auto">
              {displayPrompt &&
                displayPrompt.completions &&
                displayPrompt.completions.length > 0 &&
                examples.map((example, index) => {
                  const completion =
                    displayPrompt.completions &&
                    displayPrompt?.completions[index];

                  if (!completion) {
                    return null;
                  }

                  let formattedExampleInput;
                  if (
                    typeof example.input === 'string' ||
                    example.input instanceof String
                  ) {
                    formattedExampleInput = (
                      <span className="font-roboto text-medium font-normal text-[#6B7280]">
                        {example.input.replace(/[^\w\s]/gi, '')}
                      </span>
                    );
                  } else if (Object.keys(example.input).length > 1) {
                    formattedExampleInput = Object.keys(example.input).map(
                      (key, index) => (
                        <li
                          className="font-roboto text-medium font-normal text-[#6B7280]"
                          key={index}
                        >
                          <span className="text-ellipsis font-bold">{key}</span>
                          :{' '}
                          {JSON.stringify(example.input[key], (key, value) => {
                            return value.replace(/[^\w\s]/gi, '');
                          })}
                        </li>
                      ),
                    );
                  } else {
                    formattedExampleInput = (
                      <li
                        className="font-roboto text-medium font-normal text-[#6B7280]"
                        key={index}
                      >
                        {JSON.stringify(
                          Object.values(example.input)[0],
                          (key, value) => {
                            return value.replace(/[^\w\s]/gi, '');
                          },
                          2,
                        )}
                      </li>
                    );
                  }

                  return (
                    <div
                      key={index}
                      className={`flex flex-grow flex-col bg-[#ABB6ED29] ${selectedCompletion === index ? 'flex' : 'hidden'} overflow-auto p-3`}
                    >
                      <div className="flex w-full flex-row flex-nowrap justify-between p-3">
                        {taskInfo?.criteria.map((criterion, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between"
                          >
                            <ScoringTooltip criteria={criterion}>
                              <span className="text-xs text-[#6B7280] underline">
                                {criterion.criterion_name}
                              </span>
                            </ScoringTooltip>
                            <span
                              className="ml-2 text-sm"
                              style={{
                                color: scoreToColor(
                                  completion.scoreResponse.scores[index],
                                ),
                              }}
                            >
                              {to100pointScale(
                                completion.scoreResponse.scores[index],
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="flex h-full max-h-[33vh] flex-col overflow-y-auto">
                        <ul className="p-3">{formattedExampleInput}</ul>
                        <h3 className="mt-4 px-3 font-bold">Gold Standard</h3>
                        <pre className="font-menlo p-3 text-xs font-normal text-black">
                          {example.target}
                        </pre>
                        <h3 className="mt-4 px-3 font-bold">Output</h3>
                        <pre className="font-menlo max-w-2xl p-3 text-xs font-normal text-black">
                          {completion.prediction}
                        </pre>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
