import {
  CheckBadgeIcon,
  InformationCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import {MinusIcon} from '@heroicons/react/24/solid';
import {Button, Tooltip} from '@nextui-org/react';
import React, {useMemo, useState} from 'react';

import {AddCriteriaModal} from './AddCriteriaModal';
import {DeleteCriteriaConfirmationModal} from './DeleteCriteriaConfirmationModal';
import {Criterion} from '../types/Criterion';
import {TaskInfo} from '../types/TaskInfo';
import {ColorMap} from '../utils/scoreToColor';
import {parseScoreDescription} from '../utils/scoringGuidelines';

export default function CriteriaCard({
  taskInfo,
  addCriteria,
  newCriteriaNames,
  removeCriteria,
  promptData,
}: {
  taskInfo: TaskInfo;
  addCriteria: (criterion: Criterion) => void;
  newCriteriaNames: string[];
  removeCriteria: (criterion: Criterion) => void;
  promptData: {basePrompt: string; examples: Array<string>[]};
}) {
  const {criteria} = taskInfo;
  const [addCriteriaModalOpen, setAddCriteriaModalOpen] = useState(false);
  const [criterionToDelete, setCriterionToDelete] = useState<Criterion | null>(
    null,
  );

  const disableDelete = useMemo(() => criteria.length <= 1, [criteria]);
  const disableAdd = useMemo(() => criteria.length >= 5, [criteria]);

  return (
    <div className="pb-8">
      <div className="bg-white px-5 py-6 shadow">
        <div className="mb-4 mr-12 flex flex-row content-center items-center justify-between">
          <div className="flex flex-row items-center justify-between">
            <h2 className="font-chivo text-m text-left">Criteria</h2>
            <Tooltip
              placement="bottom-start"
              radius="none"
              style={{boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)'}}
              content={
                <div className="flex flex-row flex-nowrap items-center justify-between">
                  <img
                    src="/magic-wand.png"
                    alt="magic wand"
                    className="h-[26.5px] w-[26.5px]"
                  />
                  <p className="font-roboto m-2 text-sm font-normal text-[#374151]">
                    This criteria is automatically generated using AI based on
                    the prompt and data
                    <br />
                    provided.
                  </p>
                </div>
              }
            >
              <InformationCircleIcon className="ml-3 h-5 w-5 text-[#485ED5]" />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {criteria.map((criterion, index) => {
            const scoreDescription = parseScoreDescription(
              criterion.scoring_guidelines,
            );

            const isNew = newCriteriaNames.includes(criterion.criterion_name);
            return (
              <div
                key={index}
                className="mb-3 grid grid-cols-[3fr_4fr_1fr] items-center gap-4 border-b border-[#D1D5DB] pb-3"
              >
                <div className="flex flex-col items-start justify-between">
                  <h3 className="font-roboto mb-1.5 inline-flex flex-row flex-nowrap items-center justify-between text-sm font-semibold text-[#374151]">
                    {criterion.criterion_name}
                    {isNew && (
                      <div className="ml-3 inline-flex flex-row flex-nowrap bg-[#E1F7E6] px-2 py-1.5">
                        <CheckBadgeIcon className="h-4 w-4 text-[#28871B]" />
                        <span className="font-roboto ml-1 text-xs text-[#28871B]">
                          New
                        </span>
                      </div>
                    )}
                  </h3>
                  <p className="font-roboto text-left text-sm font-normal text-[#6B7280]">
                    {criterion.assessment}
                  </p>
                </div>
                <div>
                  {scoreDescription.map(({scores, description}, index) => (
                    <div
                      key={index}
                      className="my-3 grid grid-cols-[10px_3rem_auto] items-center gap-x-2"
                    >
                      <span
                        className="inline-block rounded-full"
                        style={{
                          backgroundColor: ColorMap[scores],
                          width: '10px',
                          height: '10px',
                        }}
                      />
                      <span className="font-roboto w-[3rem] text-left text-xs font-medium text-[#6B7280]">
                        {scores}
                      </span>
                      <span className="text-left text-xs font-normal text-[#6B7280]">
                        {description}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex flex-row items-center justify-end">
                  <Button
                    onClick={() => setCriterionToDelete(criterion)}
                    radius="none"
                    disabled={disableDelete}
                    className={`font-roboto px-3 py-2 text-xs font-medium ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${disableDelete ? 'hidden' : 'bg-white'}`}
                    startContent={<MinusIcon className="h-4 w-4 text-black" />}
                  >
                    <span className="text-[#374151]">Delete Criteria</span>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mb-3 mt-6 flex flex-row items-center justify-start">
          <Button
            radius="none"
            startContent={<PlusCircleIcon className="h-5 w-5 text-[#6B7280]" />}
            className={`font-roboto px-3 py-2 text-sm font-medium ${disableAdd ? 'bg-gray-50 text-gray-500 ring-0 hover:border-none' : 'bg-white text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}`}
            onClick={() => setAddCriteriaModalOpen(true)}
            disabled={disableAdd}
          >
            <span className="text-[#6B7280]">Add New</span>
          </Button>
        </div>
      </div>
      <AddCriteriaModal
        isOpen={addCriteriaModalOpen}
        onClose={() => setAddCriteriaModalOpen(false)}
        addCriteria={(criterion: Criterion) => {
          addCriteria(criterion);
        }}
        promptData={promptData}
      />
      <DeleteCriteriaConfirmationModal
        isOpen={Boolean(criterionToDelete)}
        onClose={() => setCriterionToDelete(null)}
        onDelete={() => {
          setCriterionToDelete(null);
          removeCriteria(criterionToDelete!);
        }}
        criterion={criterionToDelete}
      />
    </div>
  );
}
