/**
 * Utility functions for string manipulation and formatting.
 */

/**
 * Capitalizes the first character of a given string.
 * @param str - The input string to capitalize.
 * @returns The capitalized string.
 */
export const capitalise = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Formats a latency value into a human-readable string.
 * @param latency - The latency value in milliseconds.
 * @returns A formatted string representing the latency in seconds or milliseconds.
 */
export const prettyLatency = (latency: number): string => {
  const seconds = latency / 1000;
  if (seconds < 1) {
    return `${Math.round(latency)}ms`;
  }
  return `${seconds.toFixed(2)}s`;
};

/**
 * Formats a score value to a fixed-point notation with 2 decimal places.
 * @param score - The score value to format.
 * @returns A formatted string representing the score.
 */
export const prettyScore = (score: number): string => {
  return (Math.floor(score * 100) / 100).toFixed(2);
};

/**
 * Extracts the first segment of a UUID.
 * @param version - The full UUID string.
 * @returns The first segment of the UUID.
 */
export const prettyUuid = (version: string): string => {
  return version.split('-')[0];
};

/**
 * Converts an ISO date string to a localized date and time string.
 * @param isoDateString - The ISO 8601 formatted date string.
 * @returns A formatted string with the local date and time.
 */
export const formatToLocalDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const localDate = date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const localTime = date.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return `${localDate} ${localTime}`;
};

export const to2SignificantFigures = (num: number): string => {
  if (num === 0) return '0.00';

  if (num >= 1) {
    return num.toFixed(2);
  }

  if (num < 1) {
    return num.toFixed(3);
  }

  return '';
};

export const to100pointScale = (num: number | string | null): number | null => {
  if (Number.isNaN(Number(num))) return null;
  return Math.round(Number(num) * 100);
};
