import {XMarkIcon} from '@heroicons/react/20/solid';
import {Button, Modal, ModalContent} from '@nextui-org/react';
import React from 'react';

import {Criterion} from '../types/Criterion';

export interface DeleteCriteriaConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  criterion: Criterion | null;
}

export const DeleteCriteriaConfirmationModal: React.FC<
  DeleteCriteriaConfirmationModalProps
> = ({isOpen, onClose, onDelete, criterion}) => {
  if (!criterion) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      isDismissable={true}
      size="lg"
      radius="none"
    >
      <ModalContent className="flex flex-col items-center justify-start bg-white py-6">
        <div className="flex w-full flex-row items-center justify-between pl-8 pr-4">
          <h3 className="font-roboto self-end text-medium font-medium text-[#374151]">
            Are you sure you want to delete this criteria?
          </h3>
          <Button
            isIconOnly={true}
            onClick={onClose}
            radius="full"
            className="h-8 w-8 min-w-0 self-start bg-[#F9FAFB]"
          >
            <XMarkIcon className="h-5" />
          </Button>
        </div>

        <div className="mx-8 mt-6 bg-[#ABB6ED29] p-5">
          <h3 className="font-roboto mb-2 text-xs font-semibold text-[#374151]">
            {criterion.criterion_name}
          </h3>
          <p className="font-roboto mb-2 text-xs leading-5 text-[#6B7280]">
            {criterion.assessment}
          </p>
        </div>
        <Button
          className="mt-6 bg-[#485ED5] px-14 py-6"
          radius="none"
          onClick={onDelete}
        >
          <span className="text-medium text-[#F9FAFB]">Delete Criteria</span>
        </Button>
      </ModalContent>
    </Modal>
  );
};
