import './App.css';
import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import {Session, createClient} from '@supabase/supabase-js';
import React, {useState, useEffect, useCallback} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import GettingStartedCard from './components/GettingStartedCard';
import Modal from './components/SettingsModal';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import DocsPage from './pages/Docs';
import Login from './pages/Login';
import NewPromptPage from './pages/NewPromptPage';
import NotFound from './pages/NotFound';
import PromptDetailPage from './pages/PromptDetailPage';
import PromptPage from './pages/PromptPage';
import PromptTrainingPage from './pages/PromptTraining';
import useStore, {StorageKeys} from './store';
import {PromptLog} from './types/PromptLog';
import env from '../env.js';

const supabase = createClient(env.SUPABASE_URL, env.SUPABASE_CLIENT_KEY);

const initializeLocalStorage = () => {
  if (!localStorage.getItem(StorageKeys.GETTINGSTARTEDTASKS)) {
    const initialTasks = [
      {
        id: 1,
        text: 'Run your first optimization',
        completed: false,
        icon: 'ArrowPathIcon',
      },
      {
        id: 2,
        text: 'Import your own prompt',
        completed: false,
        icon: 'ArrowUpTrayIcon',
      },
      {
        id: 3,
        text: 'Collect prompt data',
        completed: false,
        icon: 'ChartBarIcon',
      },
    ];
    localStorage.setItem(
      StorageKeys.GETTINGSTARTEDTASKS,
      JSON.stringify(initialTasks),
    );
  }
};

const updateTask = (taskId: number, completed: boolean) => {
  const tasks = JSON.parse(
    localStorage.getItem(StorageKeys.GETTINGSTARTEDTASKS) || '[]',
  );
  const updatedTasks = tasks.map((task: any) =>
    task.id === taskId ? {...task, completed} : task,
  );
  localStorage.setItem(
    StorageKeys.GETTINGSTARTEDTASKS,
    JSON.stringify(updatedTasks),
  );
};

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [gettingStartedTasks, setGettingStartedTasks] = useState([]);

  const userNavigation = [
    {
      name: 'Settings',
      href: '#',
      onClick: () => {
        setSettingsModalOpen(true);
      },
    },
  ];

  const modelConfig = useStore(state => state.modelConfig);

  const registerSupabase = () => {
    supabase.auth.getSession().then(({data: {session}}) => {
      setSession(session);
      if (session) {
        useStore.getState().setSession(session);
      }
      setIsLoading(false);
    });

    const {
      data: {subscription},
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        useStore.getState().setSession(session);
      }
    });

    return () => subscription.unsubscribe();
  };

  useEffect(() => {
    registerSupabase();
    initializeLocalStorage();
    const tasks = JSON.parse(
      localStorage.getItem(StorageKeys.GETTINGSTARTEDTASKS) || '[]',
    );
    setGettingStartedTasks(tasks);
  }, []);

  const CheckSession = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const handleSessionChange = () => {
        if (!session && location.pathname !== '/login') {
          navigate('/login');
        } else if (session && location.pathname === '/login') {
          navigate('/');
        }
      };

      handleSessionChange();
    }, [session, navigate, location]);

    return null;
  };

  return (
    <>
      {session && (
        <Modal open={settingsModalOpen} setOpen={setSettingsModalOpen} />
      )}
      <Router>
        <div className="flex h-screen bg-gray-100">
          {!isLoading && <CheckSession />}{' '}
          {isLoading || session ? (
            <Sidebar
              session={session}
              userNavigation={userNavigation}
              isLoading={isLoading}
              GettingStartedCard={
                <GettingStartedCard tasks={gettingStartedTasks} />
              }
            />
          ) : null}
          <main className="flex-1 overflow-y-auto">
            <Routes>
              <Route
                path="/login"
                element={
                  <Login
                    auth={
                      <Auth
                        supabaseClient={supabase}
                        appearance={{theme: ThemeSupa}}
                        providers={['google']}
                        onlyThirdPartyProviders
                      />
                    }
                  />
                }
              />
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/prompts/create"
                element={
                  <NewPromptPage
                    onSuccess={() => {
                      updateTask(2, true);
                      setGettingStartedTasks(
                        JSON.parse(
                          localStorage.getItem(
                            StorageKeys.GETTINGSTARTEDTASKS,
                          ) || '[]',
                        ),
                      );
                    }}
                  />
                }
              />
              <Route path="/docs" element={<DocsPage />} />
              <Route path="/prompts" element={<Dashboard />} />
              <Route
                path="/training/prompts"
                element={
                  <PromptTrainingPage
                    modelConfig={modelConfig as any}
                    session={session!}
                    onSuccess={(score: number) => {
                      updateTask(1, true);
                      setGettingStartedTasks(
                        JSON.parse(
                          localStorage.getItem(
                            StorageKeys.GETTINGSTARTEDTASKS,
                          ) || '[]',
                        ),
                      );
                    }}
                  />
                }
              />
              <Route
                path="/prompts/:promptId"
                element={
                  <PromptPage
                    onLogsFound={useCallback(
                      (logs: PromptLog[]) => {
                        if (logs.length > 0) {
                          updateTask(3, true);
                          setGettingStartedTasks(
                            JSON.parse(
                              localStorage.getItem(
                                StorageKeys.GETTINGSTARTEDTASKS,
                              ) || '[]',
                            ),
                          );
                        }
                      },
                      [updateTask, setGettingStartedTasks],
                    )}
                  />
                }
              />
              <Route
                path="/prompts/:promptId/logs/:logId"
                element={<PromptDetailPage />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </Router>
    </>
  );
}
