import {EventSourcePolyfill} from 'event-source-polyfill';

import axiosInstance, {getHeaders} from './axiosInstance';
import {Criterion} from './types/Criterion';
import {PromptLog} from './types/PromptLog';

// Agent registration and management
export const registerAgent = async (
  initialPrompt: string = '',
  evalSet: any = null,
  modelConfig: any,
  userId: string,
  isSoftEval: boolean,
  taskInfo?: {
    taskName: string;
    criteria: Criterion[];
  },
) => {
  try {
    const timestamp = new Date().getTime();
    const response = await axiosInstance.post('/prompt/ephemeral/', {
      clientId: `${userId}-${timestamp}`,
      initialPrompt,
      evalSet,
      modelConfig,
      isSoftEval,
      taskInfo,
    });
    return response.data;
  } catch (error) {
    console.error('Error registering agent:', error);
    throw new Error('Failed to register agent');
  }
};

// generate criterion by user description
export const generateCriterion = async (
  description: string,
  promptData: {basePrompt: string; examples: Array<string>[]},
) => {
  try {
    const response = await axiosInstance.post(
      'prompt/ephemeral/generate-criterion',
      {
        description,
        basePrompt: promptData.basePrompt,
        examples: promptData.examples,
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error generating criterion:', error);
    throw new Error('Failed to generate criterion');
  }
};

// Server-Sent Events setup
export const startSSE = async (
  onmessage: (data: any) => void,
  clientId: string,
) => {
  const urlFromInstance = axiosInstance.defaults.baseURL;

  try {
    const newSse = new EventSourcePolyfill(
      `${urlFromInstance}/prompt/ephemeral/${clientId}`,
      {
        headers: getHeaders(),
        heartbeatTimeout: 300000,
      },
    );

    newSse.onmessage = event => {
      onmessage(JSON.parse(event.data));
    };

    newSse.onerror = event => {
      console.error('SSE error:', event);
      newSse.close();
    };
    return newSse;
  } catch (error) {
    console.error('Error starting SSE:', error);
    throw new Error('Failed to start SSE');
  }
};

// Prompt operations
export const runPrompt = async (promptObject: any, userId: string) => {
  try {
    const response = await axiosInstance.post('/prompt/ephemeral/run', {
      clientId: userId,
      promptObject,
    });
    return response.data;
  } catch (error) {
    console.error('Error running prompt:', error);
    throw new Error('Failed to run prompt');
  }
};

export const getPrompt = async (promptId: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/prompts/${promptId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching prompt:', error);
    throw new Error('Failed to fetch prompt');
  }
};

export const getPromptLogs = async (
  promptId: string,
  page = 1,
  limit = 100,
): Promise<{
  data: PromptLog[];
  meta: {page: number; perPage: number; count: number};
}> => {
  try {
    const response = await axiosInstance.get(
      `/prompts/${promptId}/executions`,
      {
        params: {
          page,
          limit,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching prompt logs:', error);
    throw new Error('Failed to fetch prompt logs');
  }
};

export const getPrompts = async (): Promise<any[]> => {
  try {
    const response = await axiosInstance.get('/prompts/');
    return response.data;
  } catch (error) {
    console.error('Error fetching prompts:', error);
    throw new Error('Failed to fetch prompts');
  }
};

export const savePrompt = async ({content, name = 'New Prompt'}: any) => {
  try {
    const response = await axiosInstance.post('/prompts/', {
      content,
      description: '',
      tags: [],
      name,
    });
    return response.data;
  } catch (error) {
    console.error('Error saving prompt:', error);
    throw new Error('Failed to save prompt');
  }
};

export const deletePrompt = async (promptId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/prompts/${promptId}`);
  } catch (error) {
    console.error('Error deleting prompt:', error);
    throw new Error('Failed to delete prompt');
  }
};

// Content learner operations
export const setupContentLearner = async (
  task: any,
  context: any,
  userId: any,
) => {
  try {
    const response = await axiosInstance.post('/content', {
      task,
      context,
      clientId: userId,
    });
    return response.data;
  } catch (error) {
    console.error('Error setting up content learner:', error);
    throw new Error('Failed to setup content learner');
  }
};

export const iterateContentLearner = async (
  correction: any,
  approved = false,
  userId: any,
) => {
  try {
    const response = await axiosInstance.post(`/content/${userId}/iterate`, {
      correction,
      approved,
    });
    return response.data;
  } catch (error) {
    console.error('Error iterating content learner:', error);
    throw new Error('Failed to iterate content learner');
  }
};

export const stepContentLearner = async (
  state: any,
  approved = false,
  userId: any,
) => {
  try {
    const response = await axiosInstance.post(`/content/${userId}/step`, {
      state,
    });
    return response.data;
  } catch (error) {
    console.error('Error stepping content learner:', error);
    throw new Error('Failed to step content learner');
  }
};

export const runContentLearner = async (context: any, userId: any) => {
  try {
    const response = await axiosInstance.post(`/content/${userId}/run`, {
      context,
    });
    return response.data;
  } catch (error) {
    console.error('Error running content learner:', error);
    throw new Error('Failed to run content learner');
  }
};

export const getContentLearner = async (generatorId: string) => {
  try {
    const response = await axiosInstance.get(`/content/${generatorId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting content learner:', error);
    throw new Error('Failed to get content learner');
  }
};

// Generator operations
export const runLoadedGenerator = async (context: any, generatorId: any) => {
  try {
    const response = await axiosInstance.post(`/content/run/${generatorId}`, {
      context,
    });
    return response.data;
  } catch (error) {
    console.error('Error running loaded generator:', error);
    throw new Error('Failed to run loaded generator');
  }
};

export const getGenerators = async () => {
  try {
    const response = await axiosInstance.get('/content/');
    return response.data;
  } catch (error) {
    console.error('Error getting generators:', error);
    throw new Error('Failed to get generators');
  }
};

export const renameGenerator = async (generatorId: any, newName: any) => {
  try {
    const response = await axiosInstance.put(`/content/${generatorId}`, {
      name: newName,
    });
    return response.data;
  } catch (error) {
    console.error('Error renaming generator:', error);
    throw new Error('Failed to rename generator');
  }
};

export const addPromptVersion = async (promptId: string, content: any[]) => {
  try {
    const response = await axiosInstance.post(`/prompts/${promptId}/versions`, {
      content,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding prompt version:', error);
    throw new Error('Failed to add prompt version');
  }
};

export const getExecution = async (executionId: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `prompts/executions/${executionId}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching execution:', error);
    throw new Error('Failed to fetch execution');
  }
};

export const createApiKey = async (resourceName: string): Promise<any> => {
  try {
    const response = await axiosInstance.post('/api-keys', {resourceName});
    return response.data;
  } catch (error) {
    console.error('Error creating API key:', error);
    throw new Error('Failed to create API key');
  }
};

export const listApiKeys = async (): Promise<any[]> => {
  try {
    const response = await axiosInstance.get('/api-keys');
    return response.data;
  } catch (error) {
    console.error('Error listing API keys:', error);
    throw new Error('Failed to list API keys');
  }
};

export const deleteApiKey = async (resourceName: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/api-keys/${resourceName}`);
  } catch (error) {
    console.error('Error deleting API key:', error);
    throw new Error('Failed to delete API key');
  }
};

export const verifyApiKey = async (apiKey: string): Promise<any> => {
  try {
    const response = await axiosInstance.post('/api-keys/verify', {apiKey});
    return response.data;
  } catch (error) {
    console.error('Error verifying API key:', error);
    throw new Error('Failed to verify API key');
  }
};

export const createTeamInvite = async (
  inviteeEmail: string,
  teamId: string,
): Promise<any> => {
  try {
    const response = await axiosInstance.post('/teams/invite', {
      inviteeEmail,
      teamId,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating team invite:', error);
    throw new Error('Failed to create team invite');
  }
};

export const checkUserTeamMembership = async (
  teamId: string,
): Promise<boolean> => {
  try {
    const response = await axiosInstance.get(`/teams/membership/${teamId}`);
    return response.data;
  } catch (error) {
    console.error('Error checking team membership:', error);
    throw new Error('Failed to check team membership');
  }
};

export const getUserTeam = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get('/teams/user');
    return response.data;
  } catch (error) {
    console.error("Error fetching user's team:", error);
    throw new Error("Failed to fetch user's team");
  }
};
