import {to100pointScale} from './string';

export type ScoreDescription = {
  scores: string;
  description: string;
};

export function parseScoreDescription(input: string): ScoreDescription[] {
  const result: ScoreDescription[] = [];

  const isMarkdown = input.includes('**');

  const markdownRegex =
    /- \*\*Score (\d\.\d)-(\d\.\d)\*\*:\s*(.+?)(?=(?:\\n|- \*\*Score|$))/gs;
  const plainTextRegex =
    /Score (\d\.\d)-(\d\.\d):\s*(.+?)(?=(?:Score \d\.\d|$))/gs;

  // Choose the appropriate regex based on the input format
  const regex = isMarkdown ? markdownRegex : plainTextRegex;

  let match;
  while ((match = regex.exec(input)) !== null) {
    const [, startScore, endScore, description] = match;

    // Convert scores to a 100-point scale
    const startScore100 = to100pointScale(parseFloat(startScore));
    const endScore100 = to100pointScale(parseFloat(endScore));

    if (startScore100 !== null && endScore100 !== null) {
      result.push({
        scores: `${startScore100}-${endScore100}`,
        description: description.trim(),
      });
    }
  }

  return result;
}
