import {
  Card,
  CardBody,
  ScrollShadow,
  Breadcrumbs,
  BreadcrumbItem,
  Chip,
  Skeleton,
} from '@nextui-org/react';
import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';

import {getExecution} from '../PromptServerClient';
import {PromptLog} from '../types/PromptLog';
import {calculateTaskCost} from '../utils/modelMetadata';

export default function PromptDetailPage() {
  const {promptId, logId} = useParams<{promptId: string; logId: string}>();
  const [log, setLog] = useState<PromptLog | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLogData = async () => {
      if (!logId) return;
      try {
        const executionData = await getExecution(logId);
        setLog(executionData);
      } catch (error) {
        console.error('Error fetching log data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogData();
  }, [logId]);

  if (!log && !loading) {
    return (
      <div className="mx-auto min-h-screen bg-white">
        <div>No data found for this generation.</div>
      </div>
    );
  }

  const response = log?.responses[0];
  const cost =
    response && response.body.usage
      ? calculateTaskCost(
          response.model,
          response.body.usage.prompt_tokens,
          response.body.usage.completion_tokens,
        )
      : 0;

  return (
    <div className="relative pt-[4.5rem]">
      <div className="mx-auto min-h-screen bg-white">
        <div className="-mt-[4.5rem] bg-white p-6">
          <div className="mb-2 text-left text-sm">
            <div className="mb-4 flex items-center justify-between">
              <div>
                <Breadcrumbs>
                  <BreadcrumbItem>
                    <Link to="/">Prompts</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`/prompts/${promptId}`}>Prompt Details</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    Log Details ({logId?.substring(0, 8)}...)
                  </BreadcrumbItem>
                </Breadcrumbs>
                <h1 className="font-chivo mt-2 text-xl">
                  Prompt Generation Details
                </h1>
              </div>
            </div>
            <div className="flex h-full">
              <div className="flex w-full flex-col">
                <Card
                  className="mb-6 flex flex-grow flex-col"
                  radius="none"
                  shadow="sm"
                >
                  <CardBody>
                    <h2 className="mb-2 text-xl font-semibold">Stats</h2>
                    <div className="grid grid-cols-2 gap-4">
                      {loading ? (
                        [
                          'Received At',
                          'Model',
                          'Total Tokens',
                          'Prompt Tokens',
                          'Completion Tokens',
                          'Cost',
                          'Status',
                        ].map(label => (
                          <div key={label}>
                            <p className="text-sm text-gray-600">{label}</p>
                            <Skeleton className="rounded-lg">
                              <div className="h-5 w-20 rounded-lg bg-default-200"></div>
                            </Skeleton>
                          </div>
                        ))
                      ) : (
                        <>
                          <div>
                            <p className="text-sm text-gray-600">Received At</p>
                            <p className="font-medium">
                              {log?.received_at || 'N/A'}
                            </p>
                          </div>
                          {response?.body.usage && (
                            <>
                              <div>
                                <p className="text-sm text-gray-600">Model</p>
                                <p className="font-medium">{response?.model}</p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-600">
                                  Total Tokens
                                </p>
                                <p className="font-medium">
                                  {response.body.usage.total_tokens}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-600">
                                  Prompt Tokens
                                </p>
                                <p className="font-medium">
                                  {response.body.usage.prompt_tokens}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-600">
                                  Completion Tokens
                                </p>
                                <p className="font-medium">
                                  {response.body.usage.completion_tokens}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-600">Cost</p>
                                <p className="font-medium">${cost}</p>
                              </div>
                            </>
                          )}
                          <div>
                            <p className="text-sm text-gray-600">Status</p>
                            {response?.status === 200 ? (
                              <Chip color="success" size="sm" radius="none">
                                {response.status}
                              </Chip>
                            ) : (
                              <p className="font-medium">
                                {response?.status || 'N/A'}
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <h2 className="mb-2 text-xl font-semibold">Conversation</h2>
                <Card className="mb-4" radius="none" shadow="sm">
                  <CardBody>
                    <h3 className="mb-2 font-semibold">Input Variables:</h3>
                    {loading ? (
                      <Skeleton className="rounded-lg">
                        <div className="h-20 w-full rounded-lg bg-default-200"></div>
                      </Skeleton>
                    ) : (
                      log?.input_variables &&
                      Object.entries(log.input_variables).map(
                        ([key, value]) => (
                          <div key={key} className="mb-2">
                            <p className="text-sm font-medium">{key}:</p>
                            <pre className="whitespace-pre-wrap rounded bg-gray-100 p-2 text-sm">
                              {typeof value === 'object'
                                ? JSON.stringify(value, null, 2)
                                : value}
                            </pre>
                          </div>
                        ),
                      )
                    )}
                  </CardBody>
                </Card>

                <Card
                  radius="none"
                  shadow="sm"
                  className={
                    response?.body.error?.code ? 'bg-red-50' : 'bg-green-50'
                  }
                >
                  <CardBody>
                    <h2 className="mb-2 font-semibold">
                      {loading ? (
                        <Skeleton className="rounded-lg">
                          <div className="h-5 w-20 rounded-lg bg-default-200"></div>
                        </Skeleton>
                      ) : (
                        response?.body.choices?.[0]?.message?.role || 'Response'
                      )}
                    </h2>
                    <ScrollShadow>
                      {loading ? (
                        <Skeleton className="rounded-lg">
                          <div className="h-40 w-full rounded-lg bg-default-200"></div>
                        </Skeleton>
                      ) : (
                        <pre className="whitespace-pre-wrap text-sm">
                          {response?.body.choices?.[0]?.message?.content ||
                            response?.body.code ||
                            response?.body.error?.code ||
                            'No response available'}
                        </pre>
                      )}
                    </ScrollShadow>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
