import {Tooltip} from '@nextui-org/react';
import React from 'react';

import {ColorMap} from '../utils/scoreToColor';
import {parseScoreDescription} from '../utils/scoringGuidelines';

interface Criteria {
  criterion_name: string;
  assessment: string;
  scoring_guidelines: string;
}

interface ScoringTooltipProps {
  criteria: Criteria;
  children: React.ReactNode;
}

export const ScoringTooltip: React.FC<ScoringTooltipProps> = ({
  criteria,
  children,
}) => {
  const scoreDescriptions = parseScoreDescription(criteria.scoring_guidelines);

  return (
    <Tooltip
      placement="bottom-start"
      radius="none"
      style={{boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)'}}
      content={
        <div className="flex flex-col p-3">
          <p className="mb-2 text-sm">• {criteria.assessment}</p>

          {scoreDescriptions.map(({scores, description}, index) => (
            <div key={index} className="flex items-center space-x-2">
              <span
                className="inline-block w-[1rem] rounded-full"
                style={{
                  backgroundColor: ColorMap[scores],
                  width: '10px',
                  height: '10px',
                }}
              />
              <span className="w-[3rem] font-medium text-[#6B7280]">
                {scores}
              </span>
              <span className="text-[#6B7280]">{description}</span>
            </div>
          ))}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
